<template>
  <layout page="student-classes">
    <page-title icon="lni lni-school-bench-alt" :breadcrumb="breadcrumb">Minhas Turmas</page-title>
    <div class="columns is-multiline">
      <div
        v-for="(_class) in classes"
        class="column is-one-third"
        :key="_class.id"
      >
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="@/assets/images/class_default.png" alt="Placeholder image">
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <h2>{{ _class.name }}</h2>
              <p class="mb-0"><strong>Serie:</strong> {{ _class.serie.name }}</p>
              <p><strong>Matriculado em:</strong> {{ _class.enrollmentAt | formatData }}</p>
              <router-link
                class="button is-primary is-fullwidth"
                :to="`/p/s/turmas/${_class.id}`"
              >
                Entrar na sala
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import { mapState } from 'vuex'
import { format } from 'date-fns'
import api from '@/api'
import { DASHBOARD, CLASS_LIST } from '@/config/breadcrumb/student'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'

export default {
  name: 'StudentClassesList',
  components: {
    Layout,
    PageTitle
  },
  created () {
    api.get(`/students/${this.user.id}/classes`).then(res => {
      this.classes = res.data
    })
  },
  data () {
    return {
      classes: []
    }
  },
  computed: {
    ...mapState(['user']),
    breadcrumb () {
      return [
        DASHBOARD(),
        CLASS_LIST(true)
      ]
    }
  },
  filters: {
    formatData (value) {
      if (value === '') return
      return format(new Date(value), 'd/MM/y')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
